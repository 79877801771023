import { checkSession } from '@idbs/idbs-auth/src/utils/auth-utils'

/**
 * Class that encapsulates authentication, integrating with the Auth0 hosted login page and
 * the IDBS Platform Authentication Service (PAS).
 */
class Auth {
  constructor({ idbsAuth, forceLoginForm = false }) {
    this.idbsAuth = idbsAuth
    this.forceLoginForm = forceLoginForm
  }

  /**
   * Try to send api request to check that tenant with provided name at least exists
   * @returns {Promise<boolean>}
   */
  checkTenantExists = async () => {
    try {
      await checkSession(this.idbsAuth.getTenant(), this.idbsAuth.realm)
      return true
    } catch (error) {
      return error.status === 401 || error.status === 404
    }
  }

  login = () => this.idbsAuth.login(this.forceLoginForm, this.idbsAuth.getLocation())

  logout = () => this.idbsAuth.logout(this.idbsAuth.getLocation())

  isAuthenticated = () => this.idbsAuth.isAuthenticated()

  getValidToken = () => this.idbsAuth.getValidToken()

  noToken = () => this.idbsAuth.noToken()

  /**
   * Callback triggered when the app loads.
   * @param {*} callback
   */
  handleAuthentication = callback =>
    this.idbsAuth.onPageLoad(callback, user => {
      // No-op
    })

  /** Initialise the token refresh scheduler */
  initTokenRefresh = () => this.idbsAuth.initRefresh()
}

export default Auth
