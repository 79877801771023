import styled from 'styled-components'
export const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 15% 85%;
`
export const IconContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  & svg{
    width: 35px;
  }
  margin-right: 20px;
  margin-left: 7px;
`
export const TextInputContainer = styled.div`
  display: inline-block;
`
export const TextContainer = styled.div`
  display: block;
  font-size: 14px;
  margin-top: 10px;
  word-break: break-word;
`
export const SubtitleContainer = styled.div`
  display: block;
  font-size: 16px;
  color: var(--theme-main-700);
  font-weight: 500;
`