import { ModalDialog } from '@idbs/idbs-react-components'
import { SuccessIcon, AttentionIcon } from '@idbs/idbs-react-icon';
import {
  IconContainer,
  TextInputContainer,
  BodyContainer,
  SubtitleContainer,
  TextContainer
} from "./Modal.sc";

export const Modal = ({type, visible, header, title, text, buttons, onHide}) => {

  const icons = {
    success: <SuccessIcon color='#a2cf6e' width='40px' height='40px' />,
    error: <AttentionIcon color='#e16a75' width='40px' height='40px' />,
    warning: <AttentionIcon color='#ffc245ff' width='40px' height='40px' />
  }
  return (<ModalDialog
      closeOnOverlayClick={false}
      className={`${type}-modal`}
      visible={visible}
      onHide={onHide}
      >
    <ModalDialog.Header>
      {header}
    </ModalDialog.Header>
    <ModalDialog.Body>
      <BodyContainer>
      <IconContainer>
        {icons[type]}
      </IconContainer>
      <TextInputContainer>
        <SubtitleContainer>
          {title}
        </SubtitleContainer>
        <TextContainer>
          {text}
        </TextContainer>
      </TextInputContainer>
      </BodyContainer>
    </ModalDialog.Body>
    <ModalDialog.Footer>
        {buttons}
    </ModalDialog.Footer>
  </ModalDialog>)
}