import React from 'react'
import { ThemeProvider } from 'styled-components'
import { IntlProvider } from 'react-intl'
import { defaultTheme } from '../theme'
import translations from '../translations'
import {getHelpLinkInfo} from '../api';
import {
  EWB_TENANT_MAP,
  POLAR_TENANT_MAP
} from '../constant';
export const copy = (text) => {
  navigator.clipboard.writeText(text);
}

/**
 * Gets type for a given tenant.
 */
export const getTenantTypeMap = async () => {
  try {
    const { data } = await getHelpLinkInfo()
    const themeUrl = data.data['getServiceRegistry'].url
    const tenantType = themeUrl.substring(themeUrl.lastIndexOf('/') + 1)
    return tenantType.toLowerCase() === 'polar' ? POLAR_TENANT_MAP : EWB_TENANT_MAP
  } catch (e) {
    console.warn('Failed to dynamically determine tenant type (Polar or EWB), fallback to EWB')
    return EWB_TENANT_MAP
  }
}
const withDefaultTheme = component => <ThemeProvider theme={defaultTheme}>{component}</ThemeProvider>

const withEnglish = component => (
    <IntlProvider locale='en-US' messages={translations.en}>
      {component}
    </IntlProvider>
)

export const withDefaultProviders = component => withDefaultTheme(withEnglish(component))