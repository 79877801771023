import IdbsAuth from '@idbs/idbs-auth'

const DEFAULT_SCOPES = 'platform-admin'

export const getIdbsAuth = ({ tenantName, redirectLocation, logoutRedirectUrl }) =>
  new IdbsAuth({
    tenantName,
    redirectAction: redirectLocation,
    logoutRedirectUrl,
    scopes: DEFAULT_SCOPES
  })
