export const layouts = {
  IdbsReactTable: {
    padding: {
      cellPadding: '0.25rem 0.4rem',
      headerCellPadding: '0.65rem 0.4rem'
    }
  },
  IdbsReactButton: {
    padding: {
      small: '0.25rem 0.5625rem',
      medium: '0.5rem 1.0625rem',
      large: '0.75rem 1rem'
    },
    borderRadius: {
      medium: '0.12rem'
    },
    margin: {
      medium: '0.25rem',
      increased: '0.375rem'
    }
  },
  IdbsReactLoadingIndicator: {
    padding: {
      medium: '2.85rem 2.5rem 2.5rem'
    }
  },
  IdbsReactActionPanel: {
    padding: {
      medium: '0.3rem 0.4rem',
      large: '0.35rem 0.65rem'
    }
  },
  IdbsReactDoubleRangeDatepicker: {
    margin: {
      betweenCalendars: '20px'
    },
    padding: {
      rangeDescription: '1rem 0'
    }
  }
}
