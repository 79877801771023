import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {AutoComplete, Label} from '@idbs/idbs-react-components'
import {copy} from '../../utils/utils'
import { ReactComponent as NewPageIcon } from '../../assets/new_page.svg'
import {
  BUCKET_NAME_REGEX,
  BUCKET_NAMING_LINK,
  ROLE_NAMING_LINK,
  ROLE_ARN_REGEX,
} from '../../constant'
import {
  InstructionLink,
  LabelContainer,
  SelectContainer,
  StyledButton,
  StyledTextInput,
  TextInputContainer,
  SpaceContainer
} from './Body.sc'

export const Body = ({
  awsConfig,
  regionsList,
  s3BucketName,
  setBucketName,
  s3BucketRegion,
  setRegion,
  s3RoleARN,
  setRoleARN,
  helpLink
}) => {
  const intl = useIntl()

  const disabledInputFields = () => <>{Object.keys(awsConfig).map((key) => {
    return <TextInputContainer key={key}>
      <StyledTextInput
          inputTestId={key}
          disabled={true}
          readOnly={true}
          label={intl.formatMessage({id: `app.iam.${key}`})}
          value={awsConfig[key]}
      />
      <StyledButton
          buttonColor='blue'
          onClick={() => copy(awsConfig[key])}
          data-testid={`${key}-button`}>
        {intl.formatMessage({id: `app.copy`})}
      </StyledButton>
    </TextInputContainer>
  })}</>

  const bucketFieldErrorMessage = useMemo(() =>
      (<div>{intl.formatMessage({id: 'app.bucketName.error'})}
        <a target="_blank" rel="noreferrer" href={BUCKET_NAMING_LINK}>
          {intl.formatMessage({id: 'app.rules.link'})}
        </a>
      </div>),
      [intl])
  const roleFieldErrorMessage = useMemo(() =>
        (<div>{intl.formatMessage({id: 'app.role.arn.error'})}
          <a target="_blank" rel="noreferrer" href={ROLE_NAMING_LINK}>
            {intl.formatMessage({id: 'app.rules.link'})}
          </a>
        </div>),
        [intl])

  return <div className="page-body">
    <LabelContainer>
      {intl.formatMessage({id: 'app.instruction'})}
      <InstructionLink target="_blank" rel="noreferrer" href={helpLink}>
        {intl.formatMessage({id: 'app.instruction.link'})}
        <NewPageIcon />
      </InstructionLink>
    </LabelContainer>

    <LabelContainer>
      {intl.formatMessage({id: 'app.iam.pre.setup.label'})}
    </LabelContainer>
      {disabledInputFields()}

    <SpaceContainer>
      {intl.formatMessage({id: 'app.iam.setup.label'})}
    </SpaceContainer>

    <TextInputContainer>
      <StyledTextInput
          label={intl.formatMessage({id: `app.iam.bucketName`})}
          value={s3BucketName}
          onChange={setBucketName}
          inputTestId="setup-bucket-name"
          error={(s3BucketName?.match(BUCKET_NAME_REGEX) || s3BucketName === '')
              ? ''
              : bucketFieldErrorMessage}
        />
    </TextInputContainer>
      <SelectContainer>
      <Label
          text={intl.formatMessage({id: `app.iam.region`})}/>
      <AutoComplete
          testId="aws-region-input"
          noOptionsMessage={() => intl.formatMessage({id: `app.no.options`})}
          data={regionsList}
          defaultValue={{label: s3BucketRegion}}
          onSelected={setRegion}/>
    </SelectContainer>
      <TextInputContainer>
      <StyledTextInput
          label={intl.formatMessage({id: `app.iam.setupRoleArn`})}
          value={s3RoleARN}
          onChange={setRoleARN}
          inputTestId="setup-role-arn"
          error={(s3RoleARN?.match(ROLE_ARN_REGEX) || s3RoleARN === '')
              ? ''
              : roleFieldErrorMessage}/>
    </TextInputContainer>
  </div>
}

