import { MESSAGE_TYPE } from './message'
import { IDBS_CLOUD_DOMAIN, IDBS_STAGING_DOMAIN, IDBS_DEV_DOMAIN } from '../constant.js'

/**
 * Returns true if we are running in anything but production mode.
 */
export const isDevMode = () => process.env.NODE_ENV !== 'production'

/**
 * Returns true if the application is run inside iFrame as plugin
 */
export const isSubFrame = () => {
  try {
    return window.parent.location.host !== window.location.host
  } catch (e) {
    console.log(e)
    // usually exception happens because cross-frames access is not allowed
    // therefore the method assumes the app running in subframe
    return true
  }
}

/**
 * Returns true if the event can be trusted, i.e., it's origin is OK, false otherwise.
 * @param {*} event  The event.
 */
export const trustMessage = event =>
  isDevMode()
    ? event.origin.startsWith(`${window.location.protocol}//${window.location.hostname}`)
    : event.origin.startsWith('https://') &&
      (event.origin.indexOf(`.${IDBS_CLOUD_DOMAIN}`) > 0 ||
        event.origin.indexOf(`.${IDBS_STAGING_DOMAIN}`) > 0 ||
        event.origin.indexOf(`.${IDBS_DEV_DOMAIN}`) > 0)

/**
 * Sanity check that the given object is not explicitly set as undefined.
 * @param {*} data The object to check.
 */
const isValid = data => typeof data !== 'undefined'

/**
 * Checks to see if the given event contains a token refresh message or not.
 * @param {*} event The event to check.
 */
export const isTokenRefreshMessage = event =>
  isValid(event) &&
  isValid(event.data) &&
  isValid(event.data.accessToken) &&
  event.data.type === MESSAGE_TYPE.TOKEN_REFRESHED
