import axios from 'axios'
import {getTenantIdFromPath} from "../plugin-sdk";
import {CONFIG_SERVICE_QUERY_TIMEOUT_MS} from "../constant";

const DEFAULT_TIMEOUT = 30_000

const getBaseUrl = () => window._API_URL || window.location.origin
export const getTenant = () => getTenantIdFromPath(window.location.pathname)
export const getConfigServiceBaseUrl = tenantName => {
  let baseUrl = window._CONFIG_SERVICE_API_URL || window.location.origin
  return baseUrl.replace('%TENANT%', tenantName)
}
const getConfigServiceApiUrl = () => `${getConfigServiceBaseUrl(getTenant())}/v1/query`
const getUrl = path => `${getBaseUrl()}/${getTenant()}${path}`
export const getAuthorizationToken = () => {
  return JSON.parse(sessionStorage.getItem('access_token'));
};
const getHeaders = (additionalHeaders = {}) => ({
  Authorization: `Bearer ${getAuthorizationToken()}`,
  ...additionalHeaders
})

const settingsUrl = () => getUrl('/onboarding/settings')
const testOnboardingUrl = () => getUrl('/onboarding/data/test')
const dataOnboardingUrl = () => getUrl('/onboarding/data')
const regionsUrl = () => getUrl('/onboarding/regions')
const statusUrl = () => getUrl('/onboarding/status')

export const settings = () =>
    axios.get(settingsUrl(), {
      headers: getHeaders(),
      timeout: DEFAULT_TIMEOUT
    })
export const testOnboarding = (payload) =>
    axios.post(
        testOnboardingUrl(),
        {
          ...payload
        },
        {
          headers: getHeaders(),
          timeout: DEFAULT_TIMEOUT
        }
    )

export const saveOnboarding = (method, payload) =>
    axios[method](
        dataOnboardingUrl(),
        {
          ...payload
        },
        {
          headers: getHeaders(),
          timeout: DEFAULT_TIMEOUT
        }
    )

export const dataOnboarding = () =>
    axios.get(dataOnboardingUrl(), {
      headers: getHeaders(),
      timeout: DEFAULT_TIMEOUT
    })

export const regions = () =>
   axios.get(regionsUrl(), {
     headers: getHeaders(),
     timeout: DEFAULT_TIMEOUT
   })

export const getHelpLinkInfo = () => {
  return axios.post(
      getConfigServiceApiUrl(),
      {
        query: 'query { getServiceRegistry(id:"theme") { url } }'
      },
      {
        headers: getHeaders(),
        timeout: CONFIG_SERVICE_QUERY_TIMEOUT_MS
      }
  )
}

export const getOnboardingAllowedStatus = (requestId) =>
    axios.get(statusUrl(), {
      headers: getHeaders({'X-Request-ID': requestId}),
      timeout: DEFAULT_TIMEOUT
    })