/**
 * Determines the tenant ID from the URL
 * @param {*} url - example format: /default-qatestenv
 */
export const getTenantIdFromPath = (url, domain = window.location.hostname) => {
  const noLeadingSlashUrl = url.startsWith('/') ? url.substring(1) : url
  const pathComponents = noLeadingSlashUrl.split('/')

  if (pathComponents.length > 0) {
    const pathIndex = isGxpDomain(domain) ? 1 : 0
    const trimmedTenantName = pathComponents[pathIndex].trim()
    if (trimmedTenantName.length > 0) {
      return trimmedTenantName
    }
  }
}

/**
 * Gets the target host for the messaging.
 * @param {*} parentUrl The URL to parse to get the host.
 */
export const getTargetHost = (parentUrl = document.referrer) => {
  if (!parentUrl) {
    return
  }
  const urlElements = parentUrl.split('/')
  return `${urlElements[0]}//${urlElements[2]}`
}

/**
 * Defines if domain passed is a GxP domain.
 * @param {*} domain The domain to parse.
 */
export const isGxpDomain = domain => domain?.includes('-gxp')
