export const colors = {
  IdbsReactTable: {
    textColor: '#353B43',
    inactiveSortingIcon: '#8E9298',
    activeSortingIcon: '#0035C6',
    headerTextColor: '#8E9298',
    headerBackground: '#ECEDEE',
    tableBorder: '#D9DADC'
  },
  IdbsReactPageHeader: {
    titleText: '#434A54',
    descriptionText: '#686E76',
    bottomLine: '#0035C6'
  },
  IdbsReactButton: {
    text: '#002895',
    textPrimary: '#ffffff',
    textDisabled: '#ffffff',
    textLink: '#395FA6',
    background: '#ebedf2',
    backgroundSecondary: '#eceff7',
    backgroundPrimary: '#002895',
    backgroundPrimaryPressed: '#00227f',
    backgroundSecondaryPressed: '#c6cfe8',
    backgroundHighlight: '#dee2ed',
    backgroundPressed: '#d1d7e8',
    backgroundHighlightPrimary: '#2648a5',
    backgroundHighlightSecondary: '#d9dfef',
    backgroundDisabled: '#d9dadc',
    backgroundDark: '#d1daf2',
    backgroundDarkHover: '#cdd6ee',
    backgroundDarkPressed: '#c3cdeb',
    border: '#98BAFF',
    explicitShadowButtonBorder: '#abbced',
    darkBorder: '#002895',
    shadow: 'inset 0 1px 1px 0 rgba(255,255,255,0.1), 0 1px 2px 0 rgba(0,0,0,0.1)',
    explicitShadow: 'inset 0 1px 5px 0 #bec7e1, 0 1px 4px 0 #bec7e1'
  },
  IdbsReactDialogs: {
    headerBackground: '#0035c6',
    headerOutline: '#d5def5',
    footerBackground: '#ecedee',
    titleColor: '#0035c6',
    textColor: '#3a4048'
  },
  IdbsReactLoadingIndicator: {
    background: '#0035c6',
    text: '#fff'
  },
  IdbsReactActionPanel: {
    background: '#D5DEF5',
    border: '#BAD1FF',
    warningBackground: '#FFCE6A',
    warningTextColor: '#353B43'
  },
  IdbsReactDatepicker: {
    monthBackground: '#ecedee',
    dayCellBackground: '#f5f5f5',
    dayInRangeBackground: '#e8ebf4',
    monthText: '#4e555e',
    weekDayText: '#798085',
    weekendText: '#9b9fa6',
    dateText: '#656c73',
    otherMonthText: '#ededed',
    monthSwitch: '#767677',
    white: '#fff',
    selectedCellBorder: '#a3a3a5'
  },
  IdbsReactDoubleRangeDatepicker: {
    clearDateText: '#dcdcde'
  }
}
