export const MESSAGE_TYPE = {
  APP_LOADED: 'AppLoaded',
  TOKEN_REFRESHED: 'TokenRefreshed'
}

/**
 * Message to be sent when the app loads successfully.
 */
export const onLoadingSuccessMessage = () => ({
  type: MESSAGE_TYPE.APP_LOADED,
  status: 'loaded'
})
