export const fonts = {
  family: {
    roboto: 'Roboto, sans-serif'
  },
  size: {
    small: '0.70rem',
    medium: '0.875rem',
    large: '1rem',
    h3: '0.825rem',
    h1: '1.5rem'
  },
  fontWeight: {
    medium: 500,
    regular: 400
  },
  letterSpacing: {
    none: '0',
    medium: '0.3px'
  },
  lineHeight: {
    small: '0.75rem',
    medium: '1.0rem',
    large: '1.25rem'
  }
}
