import React, {useEffect, useState, useMemo, useCallback} from 'react'
import {Header, Body} from './components';
import {
  LoadingIndicator,
  Button,
  Toaster,
  ContentPlaceholder,
  toast
} from '@idbs/idbs-react-components';
import {useIntl} from 'react-intl'
import * as api from './api';
import {Modal} from './components/Modal/Modal';
import {getTenantIdFromPath} from './plugin-sdk';
import {
  BUCKET_NAME_REGEX,
  EWB_TENANT_MAP,
  ROLE_ARN_REGEX,
  SAVE_TOASTER_ID,
  TOASTER_TIMEOUT_MS
} from './constant';
import '@idbs/idbs-themes/styles/polar/style.css'
import {getTenantTypeMap} from "./utils/utils";
import { v4 as uuidv4 } from 'uuid';

const App = () => {
  const intl = useIntl()
  const defaultErrorMessage = intl.formatMessage({id: 'app.error.default.text'})
  const defaultWarningSubtitle = intl.formatMessage({id: 'app.warning.default.subtitle'})
  const requestId = useMemo(() => uuidv4(), [])

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [testButtonDisabled, setTestButtonDisabled] = useState(true);
  const [isLoadingVisible, setIsLoadingVisible] = useState(true);

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);

  const [errorText, setErrorText] = useState(defaultErrorMessage);

  const [warningText, setWarningText] = useState(defaultErrorMessage);
  const [warningSubtitleText, setWarningSubtitleText] = useState(defaultWarningSubtitle);

  const [isOnboardingEnabled, setOnboardingEnabled] = useState(false);
  const [tenantTypeMap, setTenantTypeMap] = useState(EWB_TENANT_MAP)

  const [regionsList, setRegionsList] = useState([]);
  const [awsConfig, setAwsConfig] = useState({
    "awsAccountId": "",
    "externalId": "",
    "roleArn": ""
  });
  const [customerConfig, setCustomerConfig] = useState({
    "s3BucketName": "",
    "s3BucketRegion": "",
    "s3RoleARN": ""
  });
  const [s3BucketName, setBucketName] = useState('');
  const [s3BucketRegion, setRegion] = useState('');
  const [s3RoleARN, setRoleARN] = useState('');

  const token = api.getAuthorizationToken()
  
  useEffect(() => {
    ;(async function() {
      setTenantTypeMap(await getTenantTypeMap())
    })()
  }, [])

  const handleWarning = useCallback((warning) => {
    if (warning?.data?.error && warning?.data?.errorData) {
      setWarningSubtitleText(warning.data.error)
      setWarningText(warning.data.errorData)
    } else {
      setWarningSubtitleText(defaultWarningSubtitle)
      setWarningText(defaultErrorMessage)
    }
    setIsWarningModalVisible(true)
  }, [defaultWarningSubtitle, defaultErrorMessage])

  const handleCatchError = useCallback((error, check404 = true) => {
    if (error?.status !== 404 || check404) {
      if (error?.data?.errorData) {
        setErrorText(error.data.errorData)
      } else {
        setErrorText(defaultErrorMessage)
      }
      setIsErrorModalVisible(true)
    }
  }, [defaultErrorMessage])

  const handleResultStatus = useCallback((result, fn) => {
    if (result.status === 200) {
      fn()
    } else {
      handleCatchError(result)
    }
  }, [handleCatchError])

  const isConfigChanged = useCallback(() => {
    return (s3RoleARN && s3BucketName && s3BucketRegion
        && (customerConfig.s3RoleARN !== s3RoleARN
        || customerConfig.s3BucketName !== s3BucketName
        || customerConfig.s3BucketRegion !== s3BucketRegion))
  }, [customerConfig, s3RoleARN, s3BucketName, s3BucketRegion])

  const test = useCallback(async () => {
    try {
      const result = await api.testOnboarding({
        "externalId": awsConfig.externalId,
        "roleArn": s3RoleARN,
        "s3BucketName": s3BucketName,
        "s3BucketRegion": s3BucketRegion
      })
      handleResultStatus(result, () => {
        setIsSuccessModalVisible(true)
        if(isConfigChanged()){
          setSaveButtonDisabled(false)
        }
      })
      return result.status;
    } catch (error) {
      if (error?.response?.status === 400) {
        handleWarning(error?.response)
      } else {
        handleCatchError(error?.response)
      }
    }
  }, [awsConfig,
    s3RoleARN,
    s3BucketName,
    s3BucketRegion,
    handleCatchError,
    handleWarning,
    handleResultStatus,
    isConfigChanged])

  const setCustomerFields = useCallback((response) => {
    const {bucketName, region, roleArn} = response.data
    setBucketName(bucketName)
    setRegion(region)
    setRoleARN(roleArn)
    // set additional config for comparing and choosing save REST method
    setCustomerConfig({
      s3BucketName: bucketName,
      s3BucketRegion: region,
      s3RoleARN: roleArn
    })
  }, [])

  const save = useCallback(async () => {
    try {
      const method = (customerConfig.s3RoleARN
          && customerConfig.s3BucketName
          && customerConfig.s3BucketRegion) ? 'put' : 'post'
      const result = await api.saveOnboarding(method, {
        "tenantId": getTenantIdFromPath(window.location.pathname),
        "externalId": awsConfig.externalId,
        "roleArn": s3RoleARN,
        "s3BucketName": s3BucketName,
        "s3BucketRegion": s3BucketRegion
      });
      handleResultStatus(result, () => {
        setCustomerFields(result)
        setSaveButtonDisabled(true)
        const successToastId = toast.success('',
            intl.formatMessage({id: 'app.error.save.success'}),
            {containerId: SAVE_TOASTER_ID})
        setTimeout(() => toast.dismiss(successToastId), TOASTER_TIMEOUT_MS)
      })
      return result.status
    } catch (error) {
      handleCatchError(error?.response)
    }
  }, [awsConfig, s3RoleARN, s3BucketName, s3BucketRegion, intl,
    handleResultStatus, handleCatchError, customerConfig, setCustomerFields])


  const fetchInitialData = useCallback(async () => {
    const isAllowedPromise = () => new Promise((resolve, reject) => {
      api.getOnboardingAllowedStatus(requestId).then(response => {
        const isSelfOnboardingAvailable = !!response?.data?.isSelfOnboardingAvailable
        setOnboardingEnabled(isSelfOnboardingAvailable);
        resolve(isSelfOnboardingAvailable)
      }).catch(error => {
        handleCatchError(error?.response)
        reject(error)
      });
    });

    const settingsPromise = () => new Promise((resolve, reject) => {
      api.settings().then(response => {
        handleResultStatus(response, () => setAwsConfig(response.data));
        resolve()
      }).catch(error => {
        handleCatchError(error?.response)
        reject(error)
      });
    });

    const customerDataPromise = () => new Promise((resolve, reject) => {
      api.dataOnboarding().then(response => {
        handleResultStatus(response, () => {
          setCustomerFields(response)
          resolve()
        });
      }).catch(error => {
        handleCatchError(error?.response, false)
        reject(error)
      });
    });

    const regionsPromise = () => new Promise((resolve, reject) => {
      api.regions().then(response => {
        const sortedRegions = response?.data?.sort()
        handleResultStatus(response, () => setRegionsList(
            sortedRegions.map((region) => ({label: region}))))
        resolve()
      }).catch(error => {
        handleCatchError(error?.response)
        reject(error)
      });
    });

    const isAllowed = await isAllowedPromise()
    const promisesArray = []
    if (typeof isAllowed === 'boolean' && isAllowed){
      promisesArray.push(settingsPromise(), customerDataPromise(), regionsPromise())
    }

    return Promise.all(promisesArray)
  }, [handleCatchError, handleResultStatus, setCustomerFields, requestId])

  useEffect(() => {
    if (token !== null) {
      fetchInitialData().catch(error => {
        console.error(error)
      }).finally(() => setIsLoadingVisible(false));
    }
  }, [token, fetchInitialData, handleCatchError]);

  useEffect(() => {
    if (!s3RoleARN?.match(ROLE_ARN_REGEX)
        || !s3BucketRegion
        || !s3BucketName?.match(BUCKET_NAME_REGEX)) {
      setTestButtonDisabled(true)
    } else {
      setTestButtonDisabled(false)
    }
    setSaveButtonDisabled(true)
  }, [s3BucketName, s3BucketRegion, s3RoleARN]);

  const onSuccessModalHide = () => setIsSuccessModalVisible(false)
  const onErrorModalHide = () => setIsErrorModalVisible(false)
  const onWarningModalHide = () => setIsWarningModalVisible(false)

  const successModalButtons = useMemo(
      () => <>
        <Button
            large
            buttonColor='blue'
            testId="cancel-success-modal"
            onClick={onSuccessModalHide}>
          {intl.formatMessage({id: `app.cancel`})}
        </Button>
        <Button
            large
            buttonColor='blue'
            emphasis="primary"
            testId="save-success-modal"
            disabled={saveButtonDisabled}
            onClick={() => {
              onSuccessModalHide();
              save()
            }}>
          {intl.formatMessage({id: `app.test.success.save.button`})}
        </Button>
      </>,
      [intl, save, saveButtonDisabled]
  );

  const cancelModalButton = useMemo(() =>
          (fn) => <Button
              large
              emphasis="primary"
              buttonColor='blue'
              testId="cancel-error-modal"
              onClick={fn}>
            {intl.formatMessage({id: `app.cancel`})}
          </Button>
      , [intl]
  );

  return token && (
      <div className="App">
        <LoadingIndicator text={intl.formatMessage({id: 'app.loading'})}
                          visible={isLoadingVisible}/>
        <Header
            saveButtonDisabled={saveButtonDisabled}
            testButtonDisabled={testButtonDisabled}
            testFn={test}
            saveFn={save}/>
        { isOnboardingEnabled ?
        <>
          <Body
              awsConfig={awsConfig}
              regionsList={regionsList}
              s3BucketName={s3BucketName}
              setBucketName={(value) => setBucketName(value.toLowerCase().trim())}
              s3BucketRegion={s3BucketRegion}
              setRegion={(value) => {setRegion(value?.label)}}
              s3RoleARN={s3RoleARN}
              setRoleARN={(value) => setRoleARN(value.trim())}
              helpLink={tenantTypeMap.HELP_LINK}
          />
          <Modal
              type="success"
              visible={isSuccessModalVisible}
              header={intl.formatMessage({id: 'app.test.title'})}
              title={intl.formatMessage({id: 'app.test.success.title'})}
              text={intl.formatMessage({id: 'app.test.success.text'})}
              buttons={successModalButtons}
              onHide={onSuccessModalHide}
          />
          <Modal
              type="error"
              visible={isErrorModalVisible}
              header={intl.formatMessage({id: 'app.error.title'})}
              title={intl.formatMessage({id: 'app.error.subtitle'})}
              text={errorText}
              buttons={cancelModalButton(onErrorModalHide)}
              onHide={onErrorModalHide}
          />
          <Modal
              type="warning"
              visible={isWarningModalVisible}
              header={intl.formatMessage({id: 'app.warning.title'})}
              title={warningSubtitleText}
              text={warningText}
              buttons={cancelModalButton(onWarningModalHide)}
              onHide={onWarningModalHide}
          />
          <Toaster containerId={SAVE_TOASTER_ID}/>
        </> :
      <ContentPlaceholder
          testId='not-supported-placeholder'
          type='warning'
          body={
          <span>
            {intl.formatMessage({id: 'app.error.not.supported.message'}, { title: tenantTypeMap.TITLE, br: <br/>, requestId })}
          </span>
          }
          iconName='attention'
          iconColor='#ffc245'
      /> }
      </div>
  );
}

export default App;
