import {PageHeader, ActionPanel} from '@idbs/idbs-react-components'
import React from 'react'
import {useIntl} from 'react-intl'
import pageTitleIcon from '../../assets/handshake.svg'
import {StyledButton} from './Header.sc'

export const Header = ({saveButtonDisabled, testButtonDisabled, testFn, saveFn}) => {
  const intl = useIntl()

  return (
      <div>
        <PageHeader
            icon={<img src={pageTitleIcon} alt='page-title-icon'/>}
            title={intl.formatMessage({id: 'app.title'})}
            description={`- ${intl.formatMessage({id: 'app.subtitle'})}`}
        />
        <ActionPanel>
          <StyledButton
              buttonColor='blue'
              data-testid="test-button"
              disabled={testButtonDisabled}
              onClick={() => testFn()}>
            {intl.formatMessage({id: 'app.button.test'})}
          </StyledButton>
          <StyledButton
              buttonColor='blue'
              data-testid="save-button"
              disabled={saveButtonDisabled}
              onClick={() => saveFn()}>
            {intl.formatMessage({id: 'app.button.save'})}
          </StyledButton>
        </ActionPanel>
      </div>
  )
}
