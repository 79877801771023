import { getTargetHost } from './url-utils'
import { MESSAGE_TYPE, onLoadingSuccessMessage } from './message'
import { isTokenRefreshMessage, isSubFrame, trustMessage } from './utils'
import Auth from './authentication'
import { getIdbsAuth } from './authentication/idbs-auth-factory'

export const onPluginLoad = render => {
  // Post a message to state we're ready. Not sending anything sensitive at this point
  window.parent.postMessage(onLoadingSuccessMessage(), getTargetHost())
  // Register a listener to accept the access token
  window.addEventListener('message', event => {
    console.info('accessory-files-onboarding: onPluginLoad called with event', event)
    if (trustMessage(event)) {
      if (isTokenRefreshMessage(event)) {
        sessionStorage.setItem('access_token', event.data.accessToken)
        render()
      } else {
        console.warn(
          `accessory-files-onboarding: received event is not of expected token refresh message type: ${MESSAGE_TYPE.TOKEN_REFRESHED}`
        )
      }
    } else {
      console.warn(`accessory-files-onboarding: do not trust message from origin: ${event.origin}`)
    }
  })
}

export const getQueryParam = (url, param, fn) => {
  const parameterValue = new URL(url).searchParams.get(param)
  return fn ? fn(parameterValue) : parameterValue
}

export const onStandaloneLoad = async (tenantName, render, errorHandler) => {
  console.info('accessory-files-onboarding: onStandaloneLoad called with tenantName: ' + tenantName)
  const logoutRedirectUrl = `${window.location.protocol}//${window.location.host}/${tenantName}`

  const idbsAuth = getIdbsAuth({
    tenantName,
    redirectLocation: window.location.pathname,
    logoutRedirectUrl
  })
  const authentication = new Auth({
    idbsAuth,
    forceLoginForm: getQueryParam(window.location, 'forceLoginForm', val => (val ? Boolean(val) : false))
  })

  const exists = await authentication.checkTenantExists()
  if (exists) {
    authentication.handleAuthentication((err, isAuthenticated) => {
      if (err || !isAuthenticated) {
        authentication.login() // Go to the login screen
      } else {
        render()
        authentication.initTokenRefresh()
      }
    })
  } else {
    errorHandler({
      type: 'TENANT_NOT_EXIST',
      message: 'The tenant does not exist or error occurred when contacting authentication services'
    })
  }
}

export const registerPlugin = (tenantName, render, errorHandler) => {
  console.info('accessory-files-onboarding: registerPlugin called with tenantName: ' + tenantName)
  if (tenantName) {
    // If this is a subframe, then require the parent app to post the JWT into this child frame to avoid needing to run authentication
    // flows within this frame, which will be blocked because the browser will not allow 3rd party cookies within iframes, which impacts
    // redirecting to auth0.com as part of the authn/OIDC flow...
    if (isSubFrame()) {
      onPluginLoad(render)
    } else {
      // Treat this is a the app in it's own frame, i.e., top level....
      onStandaloneLoad(tenantName, render, errorHandler)
    }
  } else {
    errorHandler({
      type: 'TENANT_UNDEFINED',
      message:
        'Tenant has not been specified, please provide tenant name with url path - scheme://host:port/tenant_name'
    })
  }
}
