import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl'
import App from './App';
import { registerPlugin, getTenantIdFromPath } from './plugin-sdk'
import translations from './translations'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from './theme'
import './index.css';

const tenantName = getTenantIdFromPath(window.location.pathname)
const clientLocale = navigator.language || 'en_GB'
const language = clientLocale.split(/[-_]/)[0]
const translation = translations[language] || translations['en']

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

const render = () => root.render(
  <React.StrictMode>
    <IntlProvider locale={clientLocale} messages={translation}>
      <ThemeProvider theme={defaultTheme}>
        <App />
      </ThemeProvider>
    </IntlProvider>
  </React.StrictMode>
);

const onError = (error) => {
    console.warn(`Could not load page: ${JSON.stringify(error)}`)
    window.location = 'https://static-resources.idbs-cloud.com/error/50x.html'
}

registerPlugin(tenantName, render, onError)
