import styled from 'styled-components'
import { Button, TextInput } from '@idbs/idbs-react-components'

export const LabelContainer = styled.div`
  margin-top: 20px;
  margin-left: 15px;
  font-weight: 500;
`
export const SpaceContainer = styled.div`
  margin-top: 60px;
  margin-left: 15px;
  font-weight: 500;
`
export const InstructionLink = styled.a`
  margin-left: 5px;
  font-weight: 500;
`

export const TextInputContainer = styled.div`
  display: block;
  width: 810px;
  margin-left: 35px;
  margin-top: 20px;
`
export const SelectContainer = styled.div`
  display: block;
  width: 750px;
  margin-left: 35px;
  margin-top: 20px;
`
export const StyledTextInput = styled(TextInput)`
  display: inline-block;
  width: 750px;
`
export const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 14px !important;
  height: 29px;
  display: inline-block;
`

